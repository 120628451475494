import React from 'react'

import SEO from 'components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from 'components/layout'
import { LogoSpreadIcon } from 'components/icons'
import { Nav } from 'components/nav'
import { HeaderLink, LdJsonLogo } from 'components/semanticdata'
import FlagImage from '../../assets/blog/nextgen-eu-flag.png'
import ResilienceImage from '../../assets/blog/nextgen-eu-resilience.png'
import Analytics from '../../components/analytics'

const BlogPage = () => (
  <Layout className="bg-primary-2">
    <Analytics />
    <SEO title="Next Generation EU" pageUrl="/blog/nextgen-eu/" type="article" description="Технологична модернизация в предприятието" />
    <LdJsonLogo />
    <MainWithMenu className="relative grid">
      <Header className="z-20 pt-6 h-56">
        <HeaderLink />
      </Header>
      <Deco before after decoType="3" className="bg-primary-3 h-full v-full" gridRow={2} />
      <Section className="z-20 mb-8 text-primary-2" gridRow={2}>
        <div className="p-5 flex flex-wrap">
          <img src={FlagImage} alt="Next Generation EU" className=""/>
          <img src={ResilienceImage} alt="План за въстановяване и развитие" className=""/>
        </div>
        <h1 className="pt-5 text-xl sm:text-2xl lg:text-3xl leading-tight">
          ТУМБА СЪЛЮШЪНС ООД стартира изпълнението на проект “Технологична модернизация в предприятието”
        </h1>
        <p className="pt-5 text-sm sm:text-lg font-normal">
          Проектът се изпълнява по Национален План за Възстановяване и Устойчивост, Приоритетна ос Интелигентна индустрия,
          процедура BG-RRP-3.004 „Технологична модернизация“ от крайния получател ТУМБА СЪЛЮШЪНС ООД
        </p>
        <p className="pt-5 text-sm sm:text-lg font-normal">
          От 10.04.2023 г. ТУМБА СЪЛЮШЪНС ООД започна изпълнението на проект „Технологична модернизация в предприятието“
          по Договор за финансиране № BG-RRP-3.004-2375-C01 по процедура BG-RRP-3.004 „Технологична модернизация“.
        </p>
        <p className="pt-5 text-sm sm:text-lg font-normal">
          Проектът ще се осъществи в гр. София с финансовата подкрепа на ЕС по линия на Next Generation-EU чрез
          Национален план за възстановяване и устойчивост.
        </p>
        <p className="pt-5 text-sm sm:text-lg font-normal">
          Планираната продължителност е 12 месеца, а стойността на целия проект е в размер 694 267.74 лв.,
          от които 347 133.87 лв. безвъзмездно финансиране.
        </p>
        <p className="pt-5 text-sm sm:text-lg font-normal">
          Целта на проекта е предлагането на нов, достъпен и обществено-полезен продукт, с който Тумба Сълюшънс ООД да
          затвърди позициите си на прогресивна и отговорна компания, новатор в областта на софтуерните разработки и
          мобилни приложения.
        </p>
        <p className="pt-5 text-sm sm:text-lg font-normal">
          Проектът цели да постигне следните специфични цели:
          <ul className="pl-10 mt-2 list-disc">
            <li>цифровизация на производствения процес, което да бъде постигнато чрез използването на ИКТ технологии -
              софтуер и хардуер за разработка на уеб приложение.</li>
            <li>разширяване на капацитета на производство чрез добавяне на нови функционалности и модули към уеб
              приложението, с което то да бъде по-достъпно и по-привлекателно, съответно да разшири потребителите си.</li>
          </ul>
        </p>
        <p className="pt-12 text-xs italic font-normal">
          <div className="flex w-full">
            <hr className="flex-1 mr-3"/>
            <a href="https://www.nextgeneration.bg/">https://www.nextgeneration.bg/</a>
            <hr className="flex-1 ml-3"/>
          </div>
          Проект  BG-RRP-3.004-2375-C01 “Технологична модернизация в предприятието“ финансиран от Европейския съюз – NextGenerationEU.
          Този документ е създаден с финансовата подкрепа на Европейския съюз – NextGenerationEU. Цялата отговорност
          за съдържанието на документа се носи от ТУМБА СЪЛЮШЪНС ООД и при никакви обстоятелства не може да се приема,
          че този документ отразява официалното становище на Европейския съюз и МИР.“
        </p>
      </Section>
      <Section className="z-20 mt-40" gridRow={3}>
        <LogoSpreadIcon className="m-auto h-20" />
      </Section>
      <Deco className="bg-primary-1" gridRow={3} />
      <Nav className="z-20 text-primary-2" gridRow={4} />
    </MainWithMenu>
  </Layout>
)

export default BlogPage
